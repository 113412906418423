// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/fetchers/notification-list-fetcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/fetchers/notification-list-fetcher.tsx");
  import.meta.hot.lastModified = "1730671429329.6572";
}
// REMIX HMR END

import React from 'react';
import { useFetcher, useRevalidator } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { list } from '~admin/providers/history-item/provider';
import { formDataSearchOptions } from '~common/utils/searchOptions';
import { config } from '../config';
export async function action({
  params,
  request
}) {
  let error;
  let data;
  let page;
  const formData = await request.formData();
  const types = formData.getAll('types');
  try {
    const {
      sort,
      filter,
      skip,
      take
    } = await formDataSearchOptions(formData);
    data = await list(types?.length ? types.filter(t => t.length) : undefined, {
      filter,
      skip,
      take
    }, {
      request
    });
    page = skip;
  } catch (err) {
    error = err.message;
  }
  return json({
    data: data?.historyItems,
    types,
    page,
    error
  });
}
export const NotificationListFetcher = ({
  maxItems = 12,
  initialItems,
  initialItemCount,
  children
}) => {
  _s();
  const [exception, setException] = React.useState();
  const [changed, setChanged] = React.useState(true);
  const [filter, setFilter] = React.useState();
  const [historyTypes, setHistoryTypes] = React.useState();
  const fetcher = useFetcher({
    key: 'notifications'
  });
  const revalidator = useRevalidator();
  const [items, setItems] = React.useState({
    items: initialItems || [],
    totalItems: initialItemCount || initialItems?.length || 0
  });
  const {
    data,
    state
  } = fetcher;
  const page = data?.page ?? 0;
  const handleChangeData = React.useCallback(result => {
    setItems(items => {
      if (changed) {
        return result;
      }
      const currentLatest = items.items[items.items.length];
      const newLatest = result.items[result.items.length];
      if (!currentLatest || newLatest && currentLatest.id !== newLatest.id) {
        return {
          items: [...items.items, ...result.items],
          totalItems: result.totalItems
        };
      }
      return items;
    });
    setChanged(false);
  }, [changed]);
  React.useEffect(() => {
    if (!data || data.error || state !== 'idle') {
      return;
    }
    if (data) {
      setException(undefined);
      const result = data.data;
      handleChangeData(result);
    }
  }, [maxItems, data, state]);
  React.useEffect(() => {
    if (data?.error) {
      setException(data.error);
    }
  }, [data]);
  const handleLoad = React.useCallback((formObj = {}) => {
    if (state !== 'idle') {
      return;
    }
    const filter = formObj.filter ? {
      filter: JSON.stringify(formObj.filter)
    } : {
      filter: ''
    };
    fetcher.submit({
      take: maxItems,
      pageIndex: `${formObj.pageIndex || 0}`,
      types: formObj.types ?? [],
      ...filter
    }, {
      action: config.apiPath,
      method: 'POST'
    });
  }, [/*type, */state]);
  const handleSearch = React.useCallback(filterObj => {
    setChanged(true);
    setFilter(filterObj.filter);
    setHistoryTypes(filterObj.types);
    handleLoad(filterObj);
  }, [handleLoad]);
  const handleLoadNext = React.useCallback(() => {
    if (!changed && data?.data && data.data.items.length < maxItems) {
      return;
    }
    handleLoad({
      filter,
      types: historyTypes,
      pageIndex: page + 1
    });
  }, [page, data, changed, historyTypes, filter, handleSearch]);
  React.useEffect(() => {
    if (!initialItems) {
      handleSearch({});
    }
  }, []);
  const handleReload = React.useCallback(() => {
    handleLoad({});
  }, [handleSearch]);
  React.useEffect(() => {
    if (revalidator.state === 'loading') {
      handleReload();
    }
  }, [revalidator.state]);
  return children({
    items: items.items,
    itemCount: items.totalItems,
    exception,
    loading: state !== 'idle',
    onSearch: handleSearch,
    onNext: handleLoadNext,
    onReload: handleReload
  });
};
_s(NotificationListFetcher, "/S3O//vhZ3zG78MWda73KEN/UUQ=", false, function () {
  return [useFetcher, useRevalidator];
});
_c = NotificationListFetcher;
var _c;
$RefreshReg$(_c, "NotificationListFetcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;